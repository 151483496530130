import { Route } from 'components/ui'
import { SeoTags } from 'components/seoTags'
import { HomePage } from 'components/home'

export default function Home() {
    return (
        <Route>
            <SeoTags
                title='Crypto Staking Explorer'
                description='Staking Rewards is the central information hub and leading data aggregator for the rapidly growing $300B+ crypto staking industry, used by... Find out more'
                canonicalPath='/'
            />
            <HomePage />
        </Route>
    )
}
